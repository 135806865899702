import React, { useCallback, useContext, useEffect } from 'react';
import UserMessList from './UserMessList';
import UserMessage from './UserMessage';
import { useAppDispatch } from '../../../hooks/appHooks';
import { useSearchParams } from 'react-router-dom';

import { SocketContext, connectSocket, disconnectSocket } from '../../../context/SocketIOProvider';
import { Events } from '../../../utils/type';
import { updateMess, updateMessTeacher } from '../../../store/reducers/chatSlice';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../store/reducers/authSlice';
import { LocalStorage } from '../../../utils/LocalStorage';
import { io } from 'socket.io-client';

const MessageTeacher = () => {
  const token = LocalStorage.getAccessToken()?.toString();
  const userInfo:any =useSelector(selectUserInfo);
  console.log('🚀 ~ MessageTeacher ~ userInfo:', userInfo);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('chatID');
  const {socket} = useContext(SocketContext);
  const onSubscribe = useCallback(() => {
    if (socket) {
      console.log('🚀 ~ Message ~ socket:', socket);

      socket.emit(Events.SUBSCRIBE, {
        chatId: id,
      });
      socket.emit(Events.SUBSCRIBE_NOTI,{
        chatId:userInfo?._id
      });
    }
  }, [socket,id,userInfo,id]);
  const onReceiveMessage = useCallback((data:any)=>{
    // alert(data);
    dispatch(updateMessTeacher(data));
    console.log('🚀 ~ onReceiveMessage ~ data:', data);
    
  },[id]);
  const handleConnect = useCallback(() => {
    console.log('Hello');
    onSubscribe();
  }, [onSubscribe]);

  const handleResponseMessage = useCallback((data:any) => {
    onReceiveMessage(data);
    console.log('🚀 ~ socket.on ~ data:', data);
  }, [onReceiveMessage]);
  useEffect(() => {
    onSubscribe();
  }, [onSubscribe, socket, socket?.id,id]);
  useEffect(() => {
    if (socket) {
      
      socket.on(Events.CONNECT, handleConnect);
      socket.on(Events.RESPONSE_MESSAGE, handleResponseMessage);

      return () => {
        socket.off(Events.CONNECT, handleConnect);
        socket.off(Events.RESPONSE_MESSAGE, handleResponseMessage);
      };
    }
  }, [socket, handleConnect, handleResponseMessage,id]);
  useEffect(()=>{
    if(socket){
      if (socket.connected) {
        disconnectSocket();
      }

      connectSocket();

    }
  },[]);

  
  return (
    <div className="max-w-[1000px] p-[24px] w-full mx-auto">
      <div className='grid grid-cols-[350px_1fr] gap-x-3'>
        <div>
          <UserMessList/>
        </div>
        <div className='h-full'>

          {
            searchParams.get('chatID') && (

              <UserMessage id={searchParams.get('chatID')} socket={socket}/>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default MessageTeacher;