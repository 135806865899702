import { Button, FormLabel, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CourseLecture from './CourseLecture';
import { useAppDispatch } from '../../../hooks/appHooks';
import { addLectureCourse, deleteSectionCourse, resetQuizzLecture, updateSectionCourse } from '../../../store/reducers/updateCourseSlice';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { FaPlus, FaTrash } from 'react-icons/fa6';
import { genSlug } from '../../../utils/lib';

const CourseSection = ({sectionItem,sectionIndex,courseDetail}:any) => {
  const [sectionName, setSectionName] = useState('');
  const [open,setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const handleChangeSectionTitle = (e:any)=>{
    setSectionName(e.target.value);
    const payload:any = {
      sectionIndex: sectionIndex,
      value: e.target.value
    };
    dispatch(updateSectionCourse(payload));
  };
  const deleteSection =()=>{
    dispatch(deleteSectionCourse({
      sectionIndex:sectionIndex
    }));
  };
  const addLecture = ()=>{
    const itemLec = {
      lectureName: '',
      lectureType: '',
      duration: 0,
      url: '',
      slug:genSlug(10)
    };
    dispatch(
      addLectureCourse({
        sectionIndex: sectionIndex,
        item: itemLec,
      })
    );
  };
  useEffect(()=>{
    setSectionName(sectionItem?.sectionName);
  },[sectionItem]);
  const onOpen = ()=>{
    setOpen(true)
    dispatch(resetQuizzLecture({
      sectionIndex:sectionIndex
    }))
  }
  const onClose = ()=>{
    setOpen(false)
    dispatch(resetQuizzLecture({
      sectionIndex:sectionIndex
    }))
  }
  return (
    <div>
      <div className='p-5 border-black border-[2px] rounded-xl'>
        <div className='flex items-center justify-between gap-10 '>
          <div className='flex items-center gap-3 flex-1'>
            <FormLabel>Tên chương</FormLabel>
            <Input className='flex-1' value={sectionName} onChange={handleChangeSectionTitle}/>
          </div>
          {!open ? (
            <MdOutlineKeyboardArrowDown className='cursor-pointer text-[25px]' onClick={onOpen}/>
          ):(
            <MdOutlineKeyboardArrowUp className='cursor-pointer text-[25px]' onClick={onClose}/>
          )}
        </div>
        {
          open && (
            <div>
              {sectionItem?.lectures?.map((lectureItem:any,lectureIndex:number)=>(
                <CourseLecture key={lectureItem?._id} lectureItem={lectureItem} lectureIndex={lectureIndex} courseDetail={courseDetail} sectionIndex={sectionIndex}/>
              ))}
            </div>
          )
        }
        {courseDetail?.sections?.length > 1 && (
          <div  className='cursor-pointer flex justify-end mt-5'>
            <FaTrash onClick={deleteSection} className='text-[25px] text-[#FF6636]'/>
          </div>
        )}
        <div className='flex justify-end mt-[10px]'>

          <Button backgroundColor={'#FF6636'} onClick={addLecture}>
            <FaPlus/>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CourseSection;