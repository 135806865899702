import { Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsUpload } from 'react-icons/bs';
import { useAppDispatch } from '../../../hooks/appHooks';
import { getUserInfo, userSetting } from '../../../store/actions/user.action';
import { uploadFile } from '../../../store/actions/course.action';
import { updateUserInfo } from '../../../store/reducers/authSlice';

interface AccountSetting {
  imgUrl: string;
  fullName: string;
  username: string;
  title: string;
  bio: string;
}
const Infomation = () => {
  const [user,setUser] = useState<any>();
  const [name, setName] = useState<any>();
  const [username, setUsername] = useState<any>();
  const [loading,setLoading] = useState<any>();
  const dispatch = useAppDispatch();
  const getUserInfoTeacher = async()=>{
    const res:any = await dispatch(getUserInfo({}));
    if(res.meta.requestStatus==='fulfilled'){
      setUser(res?.payload?.data);
      setImage(res?.payload?.data?.avatar);
      setName(res?.payload?.data?.fullname);
      setUsername(res?.payload?.data?.username);
    }
  };
  useEffect(()=>{
    getUserInfoTeacher();
  },[]);
  const input = useRef<any>(null);
  const [image, setImage] = useState<any>('');
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleImageClick = () => {
    input?.current.click();
  };
  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    console.log(file);
    const base64 = await convertBase64(file);
    const formData = new FormData();
    formData.append('file', file);
    const res: any = await dispatch(uploadFile(formData));
    if (res.meta.requestStatus === 'fulfilled' && res.payload) {
      console.log(res.payload?.data?.url);
      // setValueForm('avatar', res.payload?.data?.url);
      setImage(res.payload?.data?.url);

      // dispatch(updateThumbnail(res.payload?.data?.url));
    }
  };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AccountSetting>({
    defaultValues: {
      imgUrl: '',
      fullName: '',
      username: '',
      title: '',
      bio: '',
    },
  });
  const onSubmitHandle = async (e:any) => {
    e.preventDefault();
    const payload={
      fullname:name,
      username:username,
      avatar:image
    };
    const res: any = await dispatch(userSetting(payload));
    if (res.meta.requestStatus === 'fulfilled' && res.payload) {
      console.log(res);
      setLoading(false);

      dispatch(updateUserInfo(res?.payload.data));
      // navigate("/");
    } else {
      console.log('err');
      setLoading(false);

    }
  };

  return (
    <div className="flex flex-col gap-y-[24px]">
      <h1 className="text-[#1D2026] text-[24px] font-semibold">
        Cài đặt tài khoản
      </h1>
      <form
        onSubmit={onSubmitHandle}
        className="flex flex-col gap-y-[18px]"
      >
        <div className="p-[20px] border-[1px] border-[#E9EAF0] w-fit h-fit">
          <div
            onClick={handleImageClick}
            className="w-[280px] h-[280px] relative cursor-pointer"
          >
            {image ? (
              <img src={image} alt="" className="w-full h-full object-cover" />
            ) : (
              <img
                src="https://images.pexels.com/photos/18125686/pexels-photo-18125686/free-photo-of-dem-d-ng-t-th-ch-p-nh-sang-tr-l-i.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                className="w-full h-full object-cover"
              />
            )}
            <div className="flex items-center gap-x-3 absolute bottom-0 left-0 w-full justify-center p-[12px] text-white bg-black opacity-50">
              <BsUpload className="text-[20px]" />
              <span className="text-[14px]">Tải ảnh lên</span>
            </div>
            <input
              // value={image}
              type="file"
              ref={input}
              onChange={handleImageChange}
              className="hidden"
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-[6px]">
          <span className="text-[#1D2026] text-[14px] font-normal">
            Họ và tên
          </span>
          <input
            value={name}
            onChange={(e)=>setName(e.target.value)}
            type="text"
            className="outline-none px-[18px] py-[11px] placeholder:text-[#8C94A3] text-[14px] text-[#1D2026] border-[1px] border-[#E9EAF0]"
            placeholder="VD:Nguyễn Văn A....."
          />
        </div>
        <div className="flex flex-col gap-y-[6px]">
          <span className="text-[#1D2026] text-[14px] font-normal">
            Tên người dùng
          </span>
          <input
            value={username}
            onChange={(e)=>setUsername(e.target.value)}
            type="text"
            className="outline-none px-[18px] py-[11px] placeholder:text-[#8C94A3] text-[14px] text-[#1D2026] border-[1px] border-[#E9EAF0"
            placeholder="VD: kiet132..."
          />
        </div>
        {/* <div className="flex flex-col gap-y-[6px]">
          <span className="text-[#1D2026] text-[14px] font-normal">
            Tiêu đề
          </span>
          <input
            {...register('title')}
            type="text"
            className="outline-none px-[18px] py-[11px] placeholder:text-[#8C94A3] text-[14px] text-[#1D2026] border-[1px] border-[#E9EAF0"
            placeholder="Giáo viên"
          />
        </div> */}
        {/* <div className="flex flex-col gap-y-[6px]">
          <span className="text-[#1D2026] text-[14px] font-normal">
            Tiểu sử
          </span>
          <textarea
            {...register('bio')}
            className="outline-none h-[100px] resize-none px-[18px] py-[11px] placeholder:text-[#8C94A3] text-[14px] text-[#1D2026] border-[1px] border-[#E9EAF0"
            placeholder="VD: Chào"
          ></textarea>
        </div> */}
        <Button
          w="fit-content"
          bg="#FF6636"
          _hover={{ bg: '#f55d2f' }}
          fontSize="14px"
          color="white"
          type="submit"
          isLoading={loading}
        >
          Cập nhập thông tin
        </Button>
      </form>
    </div>
  );
};

export default Infomation;
