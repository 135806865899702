import React from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import ListCart from './ListCart';
import Total from './Total';
import {motion} from "framer-motion"
const Cart = () => {
  const cardVariants = {
    hidden: {
      opacity: 0,
      y: -100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
      },
    },
  };
  return (
    <Wrapper>
      <motion.div initial="hidden"
    animate="visible"
    variants={cardVariants} className="max-w-[1200px] w-full mx-auto text-[#1D2026] min-h-[580px]">
        <h1 className="font-semibold text-[24px] mb-5 text-center py-[40px] bg-[#F5F7FA]">
          Giỏ hàng
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-[1fr_300px] gap-x-3">
          <div className="">
            <ListCart />
          </div>
          <div className="h-fit p-[10px]">
            <Total />
          </div>
        </div>
      </motion.div>
    </Wrapper>
  );
};

export default Cart;
