import React, { useEffect, useState } from 'react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { PiExamLight, PiMonitorPlayFill } from 'react-icons/pi';
import { MdOutlineQuiz } from "react-icons/md";

import { Link, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/appHooks';
import {
  updateCommentList,
  updatePage,
} from '../../store/reducers/commentSlice';
import { Button } from '@chakra-ui/react';
import { getQuizzByLecture } from '../../store/actions/quiz.action';
import { useSelector } from 'react-redux';
import { selectQuizLectureList } from '../../store/reducers/quizSlice';
import { sendMessageTelegramAction } from '../../store/actions/notification.action';
const ListVideoChapter = ({ id, code, lecture, index,slug,progress, indexSection,idLecture,sectionName}: any) => {
  console.log("🚀 ~ ListVideoChapter ~ idLecture:", idLecture)
  console.log("🚀 ~ ListVideoChapter ~ id:", id)
  // const quizLectureList:any = useSelector(selectQuizLectureList);
const [quizLectureList, setQuizLectureList] = useState<any>()
  const pathname = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  console.log(
    '🚀 ~ file: ListVideoChapter.tsx:7 ~ ListVideoChapter ~ pathname:',
    pathname
  );
  const getQuizLectureList = async(id:any)=>{
    const res:any = await dispatch(getQuizzByLecture(id));
    // setIsLoading(true);
    if(res.meta.requestStatus === 'fulfilled'){
      // setIsLoading(true);
      // const newArray = res?.payload?.data.map((item:any)=>item?.quizzId);
      // setArrayQuizId(newArray);
      setQuizLectureList(res?.payload?.data)
    }
  };
  const handleOnClick = (message:string) => {
    sendMessageTelegram(message)
    console.log('hekkk');
    dispatch(updateCommentList([]));
    dispatch(updatePage(1));
    setTimeout(() => {
      navigate(`${pathname.pathname}?idLecture=${id}&slug=${slug}`);
    }, 500);
  };
  const classCheck = ()=>{
    if(indexSection + 1 !== progress?.currentSection && indexSection + 1 <= progress?.currentSection){
      return 'cursor-pointer hover:bg-[#e9eaec]';
    }
    if(indexSection+1 === progress?.currentSection){
      if(index+1<=progress?.currentLecture){
        return 'cursor-pointer hover:bg-[#e9eaec]';
      }else{
        return 'cursor-not-allowed bg-[#D8D9DA] text-gray-500';
      }
    }
    return 'cursor-not-allowed bg-[#D8D9DA] text-gray-500';
    // if(indexSection+1 > progress?.currentSection){
    //   if(index+1<=progress?.currentLecture){
    //     return 'cursor-pointer hover:bg-[#e9eaec]';
    //   }else{
    //     return 'cursor-not-allowed bg-[#D8D9DA] text-gray-500';
    //   }
    // }
  };
  const checkIsDisable:any= ()=>{
    if(indexSection + 1 !== progress?.currentSection && indexSection + 1 <= progress?.currentSection){
      return false;
    }
    if(indexSection+1 === progress?.currentSection){
      if(index+1<=progress?.currentLecture){
        return false;
      }
      return true;
    }
    return true;
  };
  const checkClass = classCheck();
  const checkDisable = checkIsDisable();
  useEffect(()=>{
    if(id){
      getQuizLectureList(id)
    }
  },[id])
  const sendMessageTelegram = async(message:string)=>{
    const payload = {
      msg:message
    }
    try {
      const res = await dispatch(sendMessageTelegramAction(payload))
      if(res.meta.requestStatus === "fulfilled"){
        console.log(res.payload)
      }
    } catch (error) {
      console.log("🚀 ~ sendMessageTelegram ~ error:", error)
    }
  }
  return (
    <button
      onClick={()=>handleOnClick(`nhấn vào học chương ${sectionName}, bài ${lecture?.lectureName}`)}
      disabled={checkDisable}
      className={`${checkClass} ${id?.toString() === idLecture?.toString() ? "bg-gray-300":""} grid w-full grid-cols-[20px_1fr] gap-x-3 p-4  text-left`}
    >
      <AiOutlinePlayCircle className="text-[20px] mt-[0.5px]" />
      <div className="flex flex-col gap-y-2">
        <div>
          <span>{index + 1}.</span>
          <span>{lecture?.lectureName}</span>
        </div>
        <div className='grid grid-cols-2'>

          <div className="flex items-center gap-x-2">
            <MdOutlineQuiz className="text-[20px]" />
            <div>
              <p>Quizz: {quizLectureList?.length}</p>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <PiExamLight className="text-[20px]" />
            <div>
              <p>Exam: {lecture?.examId ? 1 : 0}</p>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

export default ListVideoChapter;
