import React from 'react';
import DetailBlog from '../../DetailBlog/DetailBlog';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const DetailBlogMain = () => {
  const navigate = useNavigate();
  return (
    <div className='p-[24px]'>
      <div className='mb-[20px]'>
        <div onClick={()=>navigate(-1)} className='cursor-pointer flex items-center gap-2 text-[#FF6636]'>
          <IoMdArrowRoundBack></IoMdArrowRoundBack>
          <h1 className='text-[25px] font-semibold text-[#FF6636]'>Chi tiết bài </h1>
        </div>
        <DetailBlog/>
      </div>
    </div>
  );
};

export default DetailBlogMain;