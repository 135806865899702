import { Button, Input, Select } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa6';
import { useAppDispatch } from '../../../hooks/appHooks';
import { deleteQuiz, updateLectureQuizId, updateLectureQuizQuestionTime } from '../../../store/reducers/updateCourseSlice';

const QuizItem = ({quizItem,quizList,sectionIndex,lectureIndex,quizIndex}:any) => {
  const [quizID,setQuizID] = useState<any>();
  const [time,setTime] = useState<any>();
  const dispatch = useAppDispatch();
  const handleQuizz = (e:any)=>{
    setQuizID(e.target.value);
    dispatch(updateLectureQuizId({
      sectionIndex:sectionIndex, 
      lectureIndex:lectureIndex, 
      quizIndex:quizIndex,
      value:Number(e.target.value)
    }));
  };
  const handleTiming = (e:any)=>{
    const[hours, mins] = e.target.value.split(':');
    dispatch(updateLectureQuizQuestionTime({
      sectionIndex:sectionIndex, 
      lectureIndex:lectureIndex, 
      quizIndex:quizIndex,
      value:mins*60
    }));
    console.log(mins);
  };
  const deleteQuizz= ()=>{
    dispatch(deleteQuiz({
      sectionIndex:sectionIndex,
      lectureIndex:lectureIndex,
      quizIndex:quizIndex
    }));
  };
  useEffect(()=>{
    setQuizID(quizItem?.quizzId);
    setTime(quizItem?.questionTime);
  },[quizItem]);
  return (
    <div className='flex gap-2'>
      <Select placeholder='Select quizz' value={quizID} onChange={handleQuizz}>
        {quizList?.listData?.map((itemQuizOption:any,indexQuizOption:number)=>(
          <option value={itemQuizOption?._id} key={itemQuizOption?._id}>{itemQuizOption?.title}</option>
        ))}
       
      </Select>
      <Input type='time' value={`00:${time/60 >= 10 ? time/60 : `0${time/60}`}`} onChange={handleTiming}/>
      <Button onClick={deleteQuizz}>
        <FaTrash className='text-[25px] text-[#FF6636]'/>
      </Button>
    </div>
  );
};

export default QuizItem;