import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/appHooks";
import { useSelector } from "react-redux";
import {
  selectQuizLectureList,
  updateQuizId,
  updateQuizList,
} from "../../store/reducers/quizSlice";
import { getQuizzByLecture } from "../../store/actions/quiz.action";
import { TbRuler } from "react-icons/tb";
import QuizVideo from "./QuizVideo";
import { LocalStorage } from "../../utils/LocalStorage";
import { Button, Spinner, useToast } from "@chakra-ui/react";
import {
  courseProgressAction,
  updateCourseProgressAction,
} from "../../store/actions/course.action";
import {
  updateCommentList,
  updatePage,
} from "../../store/reducers/commentSlice";
import { sendMessageTelegramAction } from "../../store/actions/notification.action";

const VideoAndQuizz = ({ courseDetail }: any) => {
  const toast = useToast();
  console.log("🚀 ~ VideoAndQuizz ~ courseDetail:", courseDetail);
  const pathname = useLocation();
  const navigate = useNavigate();
  const { idcourse } = useParams();
  const search = useLocation().search;
  const videoRef: any = useRef(null);
  const [played, setPlayed] = useState(0);
  const [progress, setProgress] = useState<any>();
  const [arrayQuizId, setArrayQuizId] = useState<any>([]);
  const [arrayQuizList, setArrayQuizList] = useState<any>();
  console.log("🚀 ~ VideoAndQuizz ~ played:", played);
  const [isPause, setIsPause] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  console.log("🚀 ~ VideoAndQuizz ~ totalTime:", totalTime);
  const dispatch = useAppDispatch();
  const paramsLecture = new URLSearchParams(search)?.get("slug");
  const idLecture = new URLSearchParams(search).get("idLecture");
  const quizLectureList: any = useSelector(selectQuizLectureList);
  console.log("🚀 ~ VideoAndQuizz ~ quizLectureList:", quizLectureList);
  const getQuizLectureList = async (id: any) => {
    setIsLoading(true);
    const res: any = await dispatch(getQuizzByLecture(id));
    if (res.meta.requestStatus === "fulfilled") {
      setIsLoading(false);
      const newArray = res?.payload?.data.map((item: any) => item?.quizzId);
      setArrayQuizId(newArray);
      setArrayQuizList(res?.payload?.data);
      dispatch(updateQuizList(res?.payload?.data));
    }
  };
  const handleDuration = (duration: any) => {
    setTotalTime(duration);
  };
  const getProgress = async (id: any) => {
    try {
      const res: any = await dispatch(courseProgressAction(id));
      if (res.meta.requestStatus === "fulfilled") {
        setProgress(res?.payload?.data);
      }
    } catch (err) {
      console.log("🚀 ~ getProgress ~ err:", err);
    }
  };
  useEffect(() => {
    getQuizLectureList(idLecture);
    if (idcourse) {
      getProgress(idcourse);
    }
  }, [idcourse, paramsLecture, idLecture]);
  const postProgress = async () => {
    const payload = {
      currentSection: progress?.currentSection,
      currentLecture: progress?.currentLecture,
      quizzIds: arrayQuizId,
    };
    const params = {
      id: idcourse,
      params: payload,
    };
    try {
      const res: any = await dispatch(updateCourseProgressAction(params));
      if (res?.meta?.requestStatus === "fulfilled") {
        setTotalTime(0)
        console.log("🚀 ~ postProgress ~ res:", res);
        dispatch(updateCommentList([]));
        dispatch(updatePage(1));
        if (res?.payload?.data?.lectureId && res?.payload?.data?.slug) {
          sendMessageTelegram(`qua bài tiếp theo: ${res?.payload?.data?.lectureName}`)
          setTimeout(() => {
            navigate(
              `${pathname.pathname}?idLecture=${res?.payload?.data?.lectureId}&slug=${res?.payload?.data?.slug}`
            );
          }, 500);
        } else {
          getProgress(idcourse);
          sendMessageTelegram('đã hoàn thành khoá học')
          toast({
            title: "Bạn đã hoàn thành khoá học",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    } catch (error) {
      console.log("🚀 ~ postProgress ~ error:", error);
    }
  };
  const sendMessageTelegram = async(message:string)=>{
    const payload = {
      msg:message
    }
    try {
      const res = await dispatch(sendMessageTelegramAction(payload))
      if(res.meta.requestStatus === "fulfilled"){
        console.log(res.payload)
      }
    } catch (error) {
      console.log("🚀 ~ sendMessageTelegram ~ error:", error)
    }
  }
  return (
    <div>
      <div className="w-full h-full lg:h-[600px] relative">
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <Spinner size={"xl"}/>
          </div>
        ) : (
          <>
            {totalTime !==0 && (
              <>
                {quizLectureList?.map((item: any, index: number) => (
                  <QuizVideo
                    played={played}
                    key={item?._id}
                    item={item}
                    setIsPause={setIsPause}
                    isPause={isPause}
                    quizList={quizLectureList}
                    videoRef={videoRef}
                    arrayQuizList={arrayQuizList}
                  />
                ))}
              </>
            )}
            <ReactPlayer
              onProgress={(duration) => {
                setPlayed(Math.floor(duration.playedSeconds));
              }}
              ref={videoRef}
              className="w-full h-full"
              controls
              width="100%"
              height="100%"
              loop={true}
              muted={true}
              playing={isPause}
              onDuration={handleDuration}
              config={{
                file: {
                  attributes: {
                    crossOrigin: "true",
                  },
                },
              }}
              url={[
                `https://staging.primeedu.io.vn/api/v1/course/lecture/${paramsLecture}`,
              ]}
            />
            {played / Math.floor(totalTime) >= 0.9 &&
              progress.progress < 100 &&
              courseDetail?.sections[progress.currentSection - 1]?.lectures[
                progress?.currentLecture - 1
              ].slug === paramsLecture && (
                <div className="absolute right-[40px] bottom-[60px]">
                  <Button
                    onClick={postProgress}
                    disabled={
                      played / Math.floor(totalTime) >= 0.9 ? false : true
                    }
                  >
                    Bài tiếp theo
                  </Button>
                </div>
              )}
          </>
        )}
      </div>
      {!isLoading && (
        <div>
          {arrayQuizList?.length > 0 && (
            <>
              <span className="font-semibold text-red-500">Thông báo:</span> Sẽ
              có bài quizz ở video vào lúc
              {arrayQuizList?.map((item: any, index: number) => (
                <span key={item?._id} className="font-semibold ml-1">
                  {item?.questionTime / 60} phút{" "}
                  <span
                    className={`${
                      item?.quizz?.isComplete
                        ? "text-green-500"
                        : ""
                    }`}
                  >
                    {item?.quizz?.isComplete ? "(Hoàn thành)" : ""}
                  </span>
                  ,
                </span>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoAndQuizz;
