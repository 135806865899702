import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './rootReducers';
import { getCourseDetail } from '../actions/course.action';
const initState={
  course:{}
};
const updateCourseSlice = createSlice({
  name:'updateCourse',
  initialState:initState,
  reducers:{
    updateTitleCourse:(state:any,actions)=>{
      state.course.courseName = actions?.payload;
    },
    updateDescCourse:(state:any,actions)=>{
      state.course.description = actions?.payload;
    },
    updateExpiredDate:(state:any,actions)=>{
      state.course.expiredDate = actions?.payload;
    },
    updatePriceCourse:(state:any,actions)=>{
      state.course.price = actions?.payload;
    },
    updateImageBanner:(state:any,actions:any)=>{
      state.course.thumbnail_url = actions?.payload;
    },
    updateSectionCourse:(state:any,actions:any)=>{
      const {sectionIndex,value} = actions.payload;
      state.course.sections[sectionIndex].sectionName = value;
    },
    addSectionCourse:(state:any,actions)=>{
      state.course.sections = [...state.course.sections, actions.payload];
    },
    deleteSectionCourse:(state:any,actions)=>{
      const {sectionIndex} = actions.payload;
      state.course.sections = state.course.sections.filter((item:any,index:number)=>index!==sectionIndex);
    },
    addLectureCourse:(state:any,actions)=>{
      const {sectionIndex,item} = actions.payload;
      state.course.sections[sectionIndex].lectures = [...state.course.sections[sectionIndex].lectures, item];
    },
    deleteLectureCourse:(state:any,actions)=>{
      const {sectionIndex,lectureIndex} = actions.payload;
      state.course.sections[sectionIndex].lectures = state.course.sections[sectionIndex].lectures.filter((item:any,index:number)=>index!==lectureIndex);
    },
    updateQuizz:(state:any,actions)=>{
      const {sectionIndex,lectureIndex,item} = actions.payload;
      if (!state.course.sections[sectionIndex].lectures[lectureIndex].quizzs) {
        state.course.sections[sectionIndex].lectures[lectureIndex].quizzs = [];
      }
      
      // Spread `item` into `quiz`
      state.course.sections[sectionIndex].lectures[lectureIndex].quizzs = item;
    },
    addQuiz:(state:any,actions)=>{
      const {sectionIndex,lectureIndex,item} = actions.payload;
      if (!state.course.sections[sectionIndex].lectures[lectureIndex].quizzs) {
        state.course.sections[sectionIndex].lectures[lectureIndex].quizzs = [];
      }
      
      // Spread `item` into `quiz`
      state.course.sections[sectionIndex].lectures[lectureIndex].quizzs.push(item);
    },
    deleteQuiz:(state:any,actions)=>{
      const { sectionIndex, lectureIndex,quizIndex } = actions.payload;
      console.log('🚀 ~ quizIndex:', quizIndex);
      
      // Spread `item` into `quiz`
      state.course.sections[sectionIndex].lectures[lectureIndex].quizzs = state.course.sections[sectionIndex].lectures[lectureIndex].quizzs.filter((item:any,index:number)=>index !== quizIndex);
    },
    updateLectureName:(state:any,actions)=>{
      const { sectionIndex, lectureIndex,value } = actions.payload;
      state.course.sections[sectionIndex].lectures[lectureIndex].lectureName = value;
    },
    updateVideoLecture:(state:any,actions)=>{
      const { sectionIndex, lectureIndex, value, urlValue } = actions.payload;
      if (value === 'VIDEO') {
        state.course.sections[sectionIndex].lectures[lectureIndex].lectureType =
          value;
        state.course.sections[sectionIndex].lectures[lectureIndex].url =
          urlValue;
          
      } else {
        state.course.sections[sectionIndex].lectures[lectureIndex].lectureType =
          value;
        state.course.sections[sectionIndex].lectures[lectureIndex].url =
          urlValue;
      }
    },
    updateLectureExamId:(state:any,actions)=>{
      const { sectionIndex, lectureIndex, value } = actions.payload;
      state.course.sections[sectionIndex].lectures[lectureIndex].examId = value;
    },
    updateLectureQuizQuestionTime:(state:any,actions)=>{
      const { sectionIndex, lectureIndex, quizIndex,value } = actions.payload;
      state.course.sections[sectionIndex].lectures[lectureIndex].quizzs[quizIndex].questionTime= value;
    },
    updateLectureQuizId:(state:any,actions)=>{
      const { sectionIndex, lectureIndex, quizIndex,value } = actions.payload;
      state.course.sections[sectionIndex].lectures[lectureIndex].quizzs[quizIndex].quizzId= value;
    },
    updateDurationLecture:(state:any,actions)=>{
      
      const { sectionIndex, lectureIndex, value } = actions.payload;
      state.course.sections[sectionIndex].lectures[lectureIndex].duration = value;
    },
    updateTotalDuration:(state:any,actions)=>{
    
      state.course.totalDuration = Number(actions.payload);
    },
    resetQuizzLecture:(state:any,actions)=>{
      const {sectionIndex} = actions.payload;
      if (!state.course.sections[sectionIndex].lectures) {
        state.course.sections[sectionIndex].lectures = [];
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(getCourseDetail.fulfilled, (state, actions) => {
      state.course = actions.payload.data;
    });
  },
});

export default updateCourseSlice.reducer;
export const {
  updateTitleCourse,
  updateDescCourse,
  updateExpiredDate,
  updatePriceCourse,
  updateImageBanner,
  updateSectionCourse,
  addSectionCourse,
  deleteSectionCourse,
  addLectureCourse,
  deleteLectureCourse,
  addQuiz,
  updateQuizz,
  deleteQuiz,
  updateLectureName,
  updateVideoLecture,
  updateLectureExamId,
  updateLectureQuizQuestionTime,
  updateLectureQuizId,
  updateDurationLecture,
  updateTotalDuration,
  resetQuizzLecture
} = updateCourseSlice.actions;
export const updateCourseShow = (state:RootState)=>state.updateCourse.course;