/* eslint-disable react/react-in-jsx-scope */
import { Flex } from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';
interface StarProps {
  star: number;
}
const Star = ({ star }: StarProps) => {
  const starArr = [0, 0, 0, 0, 0];
  return (
    <Flex>
      {starArr.map((item, index) => (
        <FaStar
          className={index<star ? 'text-yellow-400 mr-1':'text-gray-400 mr-1'}
          key={index}
        />
      )
      )}
    </Flex>
  );
};

export default Star;