import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCommentCourse } from '../../store/reducers/commentSlice';
import { useParams } from 'react-router-dom';
import { getCommentsCourse, getRatingAction, postRatingAction } from '../../store/actions/comment.action';
import { useAppDispatch } from '../../hooks/appHooks';
import { convertTimeToAgo } from '../../utils/lib';
import ReactStars from 'react-rating-stars-component';
import { Button, Textarea, useToast } from '@chakra-ui/react';
import Star from '../../components/Star/Star';
const Comment = ({courseDetail}:any) => {
  const toast = useToast();
  const [ratingList, setRatingList] = useState<any>();
  const [valuesDesc, setValuesDesc] = useState('');
  const [rate, setRate] = useState(0);
  const { idcourse } = useParams();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1); 
  const [loading,setLoading] = useState(false);
  const ratingChanged = (newRating:any) => {
    setRate(newRating);
  };
  
  const commentCourse = useSelector(selectCommentCourse);
  console.log(
    '🚀 ~ file: Comment.tsx:13 ~ Comment ~ commentCourse:',
    commentCourse
  );
  const getCommentCourseItem = async (id: any, page: number) => {
    const payload = new URLSearchParams({
      limit: '27',
      page: page.toString(),
    });
    const payloadItem = {
      courseId: Number(id),
      queryParam: payload,
    };
    
    const res = await dispatch(getCommentsCourse(payloadItem));
    if (res.meta.requestStatus === 'fulfilled' && res.payload) { /* empty */ }
  };
  const getRatingList = async()=>{
    const res:any = await dispatch(getRatingAction({}));
    if(res.meta.requestStatus === 'fulfilled'){
      setRatingList(res?.payload?.data);
    }
  };
  const handleChange = (e:any)=>{
    setValuesDesc(e.target.value);
  };
  const handleRating = async()=>{
    const payload = {
      courseId:courseDetail?._id,
      star:rate,
      content:valuesDesc
    };
    setLoading(true);
    if(rate !== 0 && valuesDesc!== ''){

      const res:any = await dispatch(postRatingAction(payload));
      if(res.meta.requestStatus === 'fulfilled'){
        setLoading(false);
        toast({
          title: 'Đánh giá thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position:'top-right'
        });
        // setRate(0);
        setValuesDesc('');
        getRatingList();

      }else{
        setLoading(false);
        toast({
          title: res.payload.message,
          status: 'warning',
          duration: 9000,
          isClosable: true,
          position:'top-right'
        });
      }
    }else{
      setLoading(false);
      toast({
        title: 'Bạn cần nhận đầy đủ các trường',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    if (idcourse) {
      getCommentCourseItem(idcourse, page);
    }
    getRatingList();
  }, [idcourse, page]);
  return (
    <div className="flex flex-col gap-y-3">
      {ratingList?.listData.length === 0 ? (
        <div>Không có đánh giá nào</div>
      ):(
        <>
          <div className='flex flex-col gap-5'>
            {ratingList?.listData
              .slice(0, 28)
              .map((comment: any, index: number) => (
                <div key={comment?._id}>
                  <div className="grid grid-cols-[50px_1fr] gap-x-5" key={index}>
                    <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                      <img
                        className="w-full h-full object-cover"
                        src={comment?.user?.avatar}
                        alt=""
                      />
                    </div>
                    <div>
                      <div className='flex items-center gap-3'>
                        <span className="font-medium text-[16px] text-[#1D2026]">
                          {comment?.user?.username}
                        </span>
                        <Star star={comment?.star}/>
                      </div>
                      <p className="text-[14px] text-[#4E5566]">{comment?.content}</p>
                    </div>
                  </div>
                  <span className="text-[14px]">
                    {convertTimeToAgo(comment?.createdAt)}
                  </span>
                </div>
              ))}
          </div>
        </>
      )}
      {courseDetail?.isPaid === true && (

        <div>
          <h1 className='text-[20px] font-semibold text-[#FF6636]'>Đánh giá khoá học</h1>
          <div>
            <ReactStars
              count={5}
              value={Number(rate)}
              onChange={ratingChanged}
              size={24}
              activeColor="#ffd700"
            />
          </div>
          <div>
            <Textarea value={valuesDesc} onChange={handleChange} resize={'none'} placeholder='Hãy để lại đánh giá của bạn ở đây'/>
          </div>
          <Button
            marginTop="20px"
            color="white"
            bg="#FF6636"
            _hover={{ bg: '#fe6131' }}
            onClick={handleRating}
            isLoading={loading}
          >
    Đánh giá
          </Button>
        </div>
      )}
    </div>
  );
};

export default Comment;
