import React, { useRef } from 'react';
import SunEditor from 'suneditor-react';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import { buttonList } from '../../../utils/type';
import { useAppDispatch } from '../../../hooks/appHooks';
import { uploadFile } from '../../../store/actions/course.action';
const Editor = ({value,handleValue}:any) => {
  const dispatch = useAppDispatch();
  /**
   * @type {React.MutableRefObject<SunEditor>} get type definitions for editor
   */
  const editor: any = useRef();
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };
  const handleImageUploadBefore = (
    files: File[],
    info: object,
    uploadHandler: (data: { result: Array<{ url: string; name: string; size: number }> }) => void
  ): any => {
    (async () => {
      try {
        const formData = new FormData();
        formData.append('file', files[0]);
  
        console.log('INFO==>', info);
  
        const response:any = await dispatch(uploadFile(formData));
  
        const data = response?.payload?.data;
  
        if (data && data.url) {
          uploadHandler({
            result: [
              {
                url: data.url,
                name: files[0].name,
                size: files[0].size,
              },
            ],
          });
        } else {
          uploadHandler({ result: [] });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        uploadHandler({ result: [] });
      }
    })();
  };
  return (
    <div>
      <SunEditor
        setOptions={{
          katex: katex,
          buttonList: buttonList,
        }}
        defaultValue={String(value)}
        onChange={handleValue}
        getSunEditorInstance={getSunEditorInstance}
        onImageUploadBefore={handleImageUploadBefore}
        height="200px"
      />
    </div>
  );
};

export default Editor;