import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/appHooks';
import { getBannerAction } from '../../store/actions/banner.action';
import parse from 'html-react-parser';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
const SliderBanner = () => {
  const [loading,setLoading] = useState(false);
  const [slides,setSlides] = useState<any>();
  const dispatch = useAppDispatch();
  const getBanner = async()=>{
    setLoading(true);
    const payload = new URLSearchParams({
      activate:'true'
    });
    const res:any = await dispatch(getBannerAction(payload));
    if(res.meta.requestStatus === 'fulfilled'){
      console.log('🚀 ~ getBanner ~ res:', res?.payload?.data);
      setLoading(false);
      setSlides(res?.payload?.data);
    }
  };
  useEffect(()=>{
    getBanner();
  },[]);
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      slidesPerView={1}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      loop={true}
    >
      {!loading && slides?.listData?.map((item:any,index:number)=>(
        <SwiperSlide key={index}>

          <div className='relative' >
            <img src={item?.url} alt="banner" className='mx-auto w-full h-[500px] object-cover'/>
            <div className='absolute left-[10px] top-[30px] lg:top-[50px] lg:left-[150px] bg-white w-[300px] lg:w-[450px] p-[24px] bg_banner'>
              <h1 className='text-[20px] lg:text-[32px] font-bold mb-0 lg:mb-[12px]'>{item?.title && parse(item?.title)}</h1>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderBanner;