import { createAsyncThunk } from '@reduxjs/toolkit';
import { bannerService } from '../services/banner.service';
export const getBannerAction = createAsyncThunk(
  'banner/getBanner',
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const res = await bannerService.getBanner(params);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createBannerAction = createAsyncThunk(
  'banner/createBanner',
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const res = await bannerService.createBanner(params);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteBannerAction = createAsyncThunk(
  'banner/createBanner',
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const res = await bannerService.deleteBanner(params);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateBannerAction = createAsyncThunk(
  'banner/updateBanner',
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const res = await bannerService.updateActiveBanner(params.id,params.params);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);