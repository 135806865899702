import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { useAppDispatch } from '../../../hooks/appHooks';
import { deleteBannerAction } from '../../../store/actions/banner.action';
const ModalDeleteBanner = ({item,getBanner,isOpen, onClose}:any) => {
  const [loadingDelete,setLoadingDelete] = useState(false);

  const dispatch = useAppDispatch();
  const deleteBanner = async(id:any)=>{
    setLoadingDelete(true);
    const res = await dispatch(deleteBannerAction(id));
    if(res.meta.requestStatus === 'fulfilled'){
      setLoadingDelete(false);
      getBanner();
      onClose();
    }
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Xoá Banner</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Bạn có muốn xoá banner <span className='text-red-500 font-semibold'>{item?.title && parse(item?.title)}</span></p>
        </ModalBody>

        <ModalFooter>
          <Button bg="#FF6636" mr={3} onClick={onClose} color="white">
              Đóng
          </Button>
          <Button variant='ghost' isLoading={loadingDelete} onClick={()=>deleteBanner(item?._id)}>Xoá</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDeleteBanner;