import React, { useEffect } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import ListCoursePayment from './ListCoursePayment';
import PaymentMethod from './PaymentMethod';
import { useSelector } from 'react-redux';
import {
  selectCartList,
  selectCartListSub,
  selectIsBuyNow,
  updateIsBuyNow,
} from '../../store/reducers/cartSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/appHooks';
import {motion} from "framer-motion"
const Payment = () => {
  const cardVariants = {
    hidden: {
      opacity: 0,
      y: -100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
      },
    },
  };
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isBuyNow = useSelector(selectIsBuyNow);
  const cartList: any = useSelector(selectCartList);
  const cartListSub: any = useSelector(selectCartListSub);
  // useEffect(()=>{
  //   if(!isBuyNow){
  //     dispatch(updateIsBuyNow(true));
  //   }
  // },[isBuyNow])
  console.log('🚀 ~ file: Payment.tsx:10 ~ Payment ~ isBuyNow:', isBuyNow);
  return (
    <Wrapper>
      <motion.div initial="hidden"
    animate="visible"
    variants={cardVariants} className="max-w-[1200px] w-full mx-auto text-[#1D2026]">
        <h1 className="font-semibold text-[24px] mb-5 text-center py-[40px] bg-[#F5F7FA]">
          Thanh toán khóa học
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-[1fr_300px] gap-x-3">
          <div className="">
            {isBuyNow ? (
              <ListCoursePayment cartList={cartListSub} />
            ) : (
              <ListCoursePayment cartList={cartList} />
            )}
          </div>
          <div className="h-fit p-[10px]">
            <PaymentMethod />
          </div>
        </div>
      </motion.div>
    </Wrapper>
  );
};

export default Payment;
