import React from 'react';
import TitleResultExam from './TitleResultExam';
import QuestionResult from './QuestionResult';
import Ranking from './Ranking';
import {motion} from "framer-motion"
const ResultExam = () => {
  const cardVariants = {
    hidden: {
      opacity: 0,
      y: -100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
      },
    },
  };
  return (
    <motion.div initial="hidden"
    animate="visible"
    variants={cardVariants} className="pt-[100px] pb-[60px] px-[24px] text-[#272829]">
      <div className="grid grid-cols-1 lg:grid-cols-[1fr_350px] gap-8">
        <div>
          <TitleResultExam />
          <QuestionResult />
        </div>
        <div>
          <Ranking />
        </div>
      </div>
    </motion.div>
  );
};

export default ResultExam;
