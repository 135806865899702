import React, { useEffect, useState } from 'react';
import MessageDetailStudent from './MessageDetailStudent';
import { useAppDispatch } from '../../hooks/appHooks';
import { getPersonChatDetail } from '../../store/actions/chat.actions';
import { useSelector } from 'react-redux';
import { selectPersonChat } from '../../store/reducers/chatSlice';
import { useLocation, useSearchParams } from 'react-router-dom';

const MessageDetail = ({socket}:any) => {
  const pathname = useLocation();
  const [loading,setLoading] = useState(false);
  console.log('🚀 ~ MessageDetail ~ pathname:', pathname);
  const [searchParams] = useSearchParams(); 
  const disptach = useAppDispatch();
  const id = searchParams.get('chatID');
  const getDetail:any = useSelector(selectPersonChat);
  console.log('🚀 ~ MessageDetail ~ getDetail:', getDetail);
  const getDetailChat = async(id:any)=>{
    setLoading(true);
    const res = await disptach(getPersonChatDetail(id));
    if(res.meta.requestStatus === 'fulfilled'){
      setLoading(false);
    }
    console.log('🚀 ~ getDetailChat ~ res:', res);
    
  };
  useEffect(()=>{
    getDetailChat(id);
  },[id, pathname]);
  return (
    <div className='h-[300px]'>
      <div className='flex gap-x-[16px] items-center px-[24px] py-[20px] border-[1px] border-[#E9EAF0]'>
        <div className='relative'>
          <img src={getDetail?.teacher?.avatar ? getDetail?.teacher?.avatar : 'https://images.pexels.com/photos/5579045/pexels-photo-5579045.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'} alt="" className='w-[60px] h-[60px] rounded-full object-cover'/>
          {/* <div className='absolute w-[10px] h-[10px] rounded-full bg-[#23BD33] bottom-[2px] right-[5px]'></div> */}
        </div>
        <h1 className='font-semibold'>{getDetail?.teacher?.username}</h1>
      </div>
      {!loading && (

        <MessageDetailStudent getDetailChat={getDetailChat} getDetail={getDetail} socket={socket} id={id}/>
      )}
    </div>
  );
};

export default MessageDetail;