import axiosClient from './axiosClient';
export const bannerService = {
  getBanner: (params: any) => {
    return axiosClient.get(`/banner/list?${params.toString()}`);
  },
  createBanner:(params:any)=>{
    return axiosClient.post('/banner/create',params);
  },
  updateActiveBanner:(id:any,params:any)=>{
    return axiosClient.patch(`/banner/update/${id}`, params);
  },
  deleteBanner:(params:any)=>{
    return axiosClient.delete(`/banner/delete/${params}`);
  }
};