/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import Question from './Question';
import { useAppDispatch } from '../../hooks/appHooks';
import { useSelector } from 'react-redux';
import { postExam, selectExamPost } from '../../store/reducers/examSlice';

const TitleAssignmet = ({ questions }: any) => {
  const [shuffledQuestions, setShuffledQuestions] = useState([]);

  useEffect(() => {
    // Function to shuffle array
    const shuffleArray = (array:any) => {
      let currentIndex = array.length, randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    };

    // Shuffle questions array when component mounts or questions prop changes
    if (questions?.questions) {
      const shuffled = shuffleArray([...questions.questions]);
      setShuffledQuestions(shuffled);
    }
  }, [questions]);
  return (
    <div className="flex flex-col gap-y-10 divide-y-2 divide-dashed">
      {shuffledQuestions?.map((question: any, index: any) => (
        <Question index={index + 1} question={question} key={question?._id} />
      ))}
    </div>
  );
};

export default TitleAssignmet;
