import { Button, FormLabel, Input, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/appHooks';
import { getQuiz, getQuizzByLecture } from '../../../store/actions/quiz.action';
import { useSelector } from 'react-redux';
import { selectExams } from '../../../store/reducers/examSlice';
import { getExam } from '../../../store/actions/exam.action';
import { MdKeyboardArrowDown, MdOutlineDriveFolderUpload } from 'react-icons/md';
import QuizItem from './QuizItem';
import { FaP, FaPlus, FaTrash } from 'react-icons/fa6';
import { addLectureCourse, addQuiz, deleteLectureCourse, resetQuizzLecture, updateDurationLecture, updateLectureExamId, updateLectureName, updateQuizz, updateVideoLecture } from '../../../store/reducers/updateCourseSlice';
import { genSlug, getVideoDuration } from '../../../utils/lib';
import { uploadVideo } from '../../../store/actions/course.action';
import ReactPlayer from 'react-player';


const CourseLecture = ({lectureItem,lectureIndex,courseDetail,sectionIndex}:any) => {
  console.log('🚀 ~ CourseLecture ~ lectureItem:', lectureItem);
  const [totalTime, setTotalTime] = useState(0);
  console.log('🚀 ~ CourseLecture ~ totalTime:', totalTime);
  const [loading,setLoading] = useState(false);
  const exams: any = useSelector(selectExams);
  const dispatch = useAppDispatch();
  const [lectureName, setLectureName] = useState<string>('');
  const [video,setVideo] = useState<any>();
  const [valueExam, setValueExam] = useState(lectureItem?.examId ? 
    exams?.listData?.filter((itemExam:any,index:number)=>itemExam?._id === lectureItem?.examId)[0]?.title
    :'Bài tập');
  const [valueExamId, setValueExamId] = useState(0);
  const refVideo = useRef<any>(null);
  const handleImageClick = () => {
    refVideo?.current.click();
  };
  const [quizListLecture, setQuizListLecture] = useState<any>([]);
  const [quizList, setQuizList] = useState<any>();
  const [loadingQuizz,setLoadingQuizz] = useState(false)
  const getQuizLecture = async(id:any)=>{
    if(id){
      setLoadingQuizz(true)
      const res:any = await dispatch(getQuizzByLecture(id));
      if(res?.meta?.requestStatus === 'fulfilled'){
        setLoadingQuizz(false)
        setQuizListLecture(res?.payload?.data);
        if(res?.payload?.data?.length === 0){
          // dispatch(updateQuizz({
          //   sectionIndex:sectionIndex,
          //   lectureIndex:lectureIndex,
          //   item:[
          //     {
          //       'quizzId': 0,
          //       'questionTime': 0
          //     },
          //   ]
          // }));
          // setQuizListLecture([
          //   {
          //     'quizzId': 0,
          //     'questionTime': 0
          //   },
          // ]);
        }else{
        // dispatch(resetQuizzLecture({
        //   sectionIndex:sectionIndex,
        //   lectureIndex:lectureIndex,
        // }))
          const cleanedArray = res?.payload?.data?.map(({ quizzId, questionTime }:any) => ({ quizzId, questionTime }));
          dispatch(updateQuizz({
            sectionIndex:sectionIndex,
            lectureIndex:lectureIndex,
            item:cleanedArray
          }));
        }
      }
    }else{
      dispatch(updateQuizz({
        sectionIndex:sectionIndex,
        lectureIndex:lectureIndex,
        item:[
          {
            'quizzId': 0,
            'questionTime': 0
          },
        ]
      }));
    }
  };
  const getQuizList = async()=>{
    const res:any = await dispatch(getQuiz({}));
    if(res?.meta?.requestStatus === 'fulfilled'){
      setQuizList(res?.payload?.data);
      
    }
  };
  const getExamsList = async () => {
    const res = await dispatch(getExam({}));
    if (res.payload && res.meta.requestStatus === 'fulfilled') { /* empty */ }
  };
  const handleClick = (id: number, title: string) => {
    setValueExam(title);
    setValueExamId(id);
    dispatch(
      updateLectureExamId({
        sectionIndex: sectionIndex,
        lectureIndex: lectureIndex,
        value: id,
      })
    );
  };
  const deleteLecture = ()=>{
    dispatch(deleteLectureCourse({
      sectionIndex:sectionIndex,
      lectureIndex:lectureIndex
    }));
  };
  const addQuizz = ()=>{
    dispatch(addQuiz({
      sectionIndex:sectionIndex,
      lectureIndex:lectureIndex,
      item:{
        quizzId:0,
        questionTime:0
      }
    }));
  };
  const handleUpdateLectureName = (e:any)=>{
    setLectureName(e.target.value);
    dispatch(updateLectureName({
      sectionIndex:sectionIndex,
      lectureIndex:lectureIndex,
      value:e.target.value
    }));
  };
  const handleChangeVideo = async(e:any)=>{
    if(e.target.files){

      setVideo(e.target.files && e.target.files[0]);
      const durationLecture = await getVideoDuration(e.target.files[0]);
      dispatch(updateDurationLecture({
        sectionIndex:sectionIndex,
        lectureIndex:lectureIndex,
        value:Number(Math.floor(Number(durationLecture)/60))
      }));
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('slug', lectureItem?.slug);
      setLoading(true);
      const res = await dispatch(uploadVideo(formData));
      if(res.meta.requestStatus==='fulfilled' && res.payload){
        console.log(res);
        setLoading(false);
        dispatch(
          updateVideoLecture({
            sectionIndex: sectionIndex,
            lectureIndex: lectureIndex,
            value: 'VIDEO',
            urlValue: e.target.files[0].name
          })
        );
        
        // onClose();
      }else{
        setLoading(false);
      }
    }
  };
  useEffect(()=>{
    getExamsList();
    getQuizList();
    setLectureName(lectureItem?.lectureName);
    setVideo(lectureItem?.url);
    setTotalTime(lectureItem?.duration);
    // setValueExam(lectureItem?.examId ? 
    //   exams?.listData?.filter((itemExam:any,index:number)=>itemExam?._id === lectureItem?.examId)[0]?.title
    //   :'Bài tập');
  },[]);
  useEffect(()=>{
    if(exams){
      setValueExam(lectureItem?.examId ? 
        exams?.listData?.filter((itemExam:any,index:number)=>itemExam?._id === lectureItem?.examId)[0]?.title
        :'Bài tập');
    }
  },[exams])
  useEffect(()=>{
    getQuizLecture(lectureItem?._id);
  },[lectureItem?._id])
  // useEffect(()=>{
  //   dispatch(updateDurationLecture({
  //     sectionIndex:sectionIndex,
  //     lectureIndex:lectureIndex,
  //     value:Number(Math.floor(totalTime/60))
  //   }));
  // },[totalTime]);
  return (
    <div className='p-5 border-black border-[2px] rounded-lg mt-5'>
      <h1>Bài học</h1>
      <div className='flex flex-col gap-3'>
        <div className='flex items-center'>
          <FormLabel>Tên bài học</FormLabel>
          <Input className='flex-1' value={lectureName} onChange={handleUpdateLectureName}/>
        </div>
        <div className='flex items-center justify-between gap-10'>
          <div className='flex-1 flex items-center gap-4'>
            <FormLabel>Video</FormLabel>
            <Input className='flex-1' value={video?.name ?video?.name : video} disabled/>
            <span>{lectureItem?.duration} phút</span>
          </div>
          <input
            ref={refVideo}
            type="file"
            onChange={handleChangeVideo}
            className="w-full px-[18px] py-[13px] border-[1px] text-[#1D2026] border-[#E9EAF0] outline-none placeholder:text-[#8C94A3] hidden"
            placeholder="Link url..."
            accept="video/*"
          />
          <Button onClick={handleImageClick} isLoading={loading}>
            <MdOutlineDriveFolderUpload className='text-[25px] text-[#FF6636]'/>
          </Button>
        </div>
        <div>
          <Menu>
            <MenuButton as={Button} rightIcon={<MdKeyboardArrowDown />}>
              {valueExam && valueExam}
            </MenuButton>
            <MenuList>
              {exams?.listData?.filter((item:any)=>item.categoryId === +courseDetail?.categoryId && +courseDetail?.subCategoryId===item?.subCategoryId)?.length === 0 ? (
                <MenuItem>Không có bài tập</MenuItem>
              ):(
                <>
                  {exams?.listData?.filter((item:any,indexExam:number)=>item.categoryId === +courseDetail?.categoryId && +courseDetail?.subCategoryId===item?.subCategoryId)?.map((itemExam: any, indexExam: number) => (
                    <MenuItem
                      key={indexExam}
                      onClick={() => handleClick(itemExam?._id, itemExam?.title)}
                    >
                      {itemExam?.title}
                    </MenuItem>
                  ))}
                </>
              )}
            </MenuList>
          </Menu>
        </div>
        <div>
          <div className='flex justify-between items-center mb-3'>
            <h1 className='mb-[10px]'>Bài quizz</h1>
            <Button bgColor={'#FF6636'} onClick={addQuizz}>
              <FaPlus />
            </Button>
          </div>
          <div className='flex flex-col gap-2'>
            {(!loadingQuizz && lectureItem?.quizzs) && lectureItem?.quizzs?.map((quizItem:any,quizIndex:number)=>(
              <QuizItem key={quizItem?._id} quizItem={quizItem} quizList={quizList} sectionIndex={sectionIndex} lectureIndex={lectureIndex} quizIndex={quizIndex}/>
            ))}
          </div>
        </div>
      </div>
      {courseDetail?.sections[sectionIndex].lectures?.length > 1 && (
        <div  className='cursor-pointer flex justify-end mt-5'>
          <FaTrash onClick={deleteLecture} className='text-[25px] text-[#FF6636]'/>
        </div>
      )}
    </div>
  );
};

export default CourseLecture;