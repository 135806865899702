import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/appHooks';
import { examHistoryStudentAction } from '../../store/actions/exam.action';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import PagiantionNew from '../../components/Pagination/PagiantionNew';

const HistoryExam = () => {
  const dispatch = useAppDispatch();
  const [listExamHistory,setListExamHistory] = useState<any>();
  const [page,setPage] = useState(1);
  const handleChange = (page: number) => {
    setPage(page);
  };
  const getHistoryExam = async(page:number)=>{
    const payload = new URLSearchParams({
      page:String(page),
      limit:'10'
    });
    try {
      const res:any = await dispatch(examHistoryStudentAction(payload));
      if(res.meta.requestStatus === 'fulfilled'){
        console.log('🚀 ~ getHistoryExam ~ res:', res);
        setListExamHistory(res?.payload?.data);
      }
    } catch (error) {
      console.log('🚀 ~ getHistoryExam ~ error:', error);
            
    }
  };
  useEffect(()=>{
    getHistoryExam(page);
  },[page]);
  return (
    <TableContainer>
      <Table variant='simple'>
    
        <Thead>
          <Tr>
            <Th>STT</Th>
            <Th>Bài kiểm tra</Th>
            <Th>Ngày làm</Th>
            <Th>Thời gian làm làm</Th>
            <Th>Số câu đúng</Th>
            <Th>Điểm số</Th>

          </Tr>
        </Thead>
        <Tbody>
          {listExamHistory?.listData?.map((item:any,index:number)=>(
            <Tr key={item?._id}>
              <Td>{index+1+(page*10 - 10)}</Td>
              <Td>{item?.exam?.title}</Td>
              <Td>{moment(item?.createdAt).format('DD/MM/YYYY')}</Td>
              <Td>{item?.completeTime}/{item?.exam?.time} phút</Td>
              <Td>{item?.corrects}/{item?.totalQuestions} câu</Td>
              <Td>{item?.score}/10 điểm</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <div className="mt-4">
        <PagiantionNew
          onPageChange={handleChange}
          totalCount={listExamHistory?.total}
          pageSize={10}
          siblingCount={1}
          currentPage={page}
        />
      </div>
    </TableContainer>
  );
};

export default HistoryExam;