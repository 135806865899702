import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/appHooks';
import { getCourseDetail, updateCourseTeacherAction, uploadFile } from '../../../store/actions/course.action';
import { selectCourseDetail } from '../../../store/reducers/courseSlice';
import { Button, FormLabel, Input, InputGroup, InputRightAddon, Spinner } from '@chakra-ui/react';
import Editor from './Editor';
import DatePicker from 'react-datepicker';
import { convertBase64, genSlug, totDurationAllUpdate } from '../../../utils/lib';
import { BsCloudUpload } from 'react-icons/bs';
import imgFade from '../../../image/CreateCourse/Icon.jpg';
import CourseSection from './CourseSection';
import { addSectionCourse, updateCourseShow, updateDescCourse, updateExpiredDate, updateImageBanner, updatePriceCourse, updateTitleCourse, updateTotalDuration } from '../../../store/reducers/updateCourseSlice';
import moment from 'moment';
import { FaPlus } from 'react-icons/fa6';

const UpdateCourseMain = () => {
  const navigation = useNavigate();
  const [loading,setLoading] = useState(false);
  const [loadingImg,setLoadingImg] = useState(false);
  const updateCourse:any = useSelector(updateCourseShow);
  console.log('🚀 ~ UpdateCourseMain ~ updateCourse:', updateCourse);
  const [titleCourse, setTitleCourse] = useState('');
  const [description, setDescription] = useState('');
  const [date,setDate] = useState(new Date());
  const [money,setMoney] = useState<number>();
  const [image, setImage] = useState<any>('');
  const [totalDuration,setDuration] = useState<number | string>();
  const refImage = useRef<any>(null);

  const { id } = useParams();
  // const courseDetail:any = useSelector(selectCourseDetail);
  const dispatch = useAppDispatch();
  const getDetailCourse = async (id: any) => {
    const res: any = await dispatch(getCourseDetail(id));
    if (res.payload && res.meta.requestStatus === 'fulfilled') {
      //   setLoading(false);
      //   setCourseDetail(res.payload.data);
      console.log(
        '🚀 ~ file: CourseItem.tsx:23 ~ getDetailCourse ~ res.payload.data:',
        res.payload.data
      );
      setTitleCourse(res?.payload?.data?.courseName);
      setDescription(res?.payload?.data?.description);
      setDate(res?.payload?.data?.expiredDate);
      setMoney(res?.payload?.data?.price);
      setImage(res?.payload?.data?.thumbnail_url);
    }
  };
  useEffect(() => {
    // setLoading(true);
    if (id) {
      getDetailCourse(id);
    }
  }, [id]);
  const handleDesc = (data:any)=>{
    setDescription(data);
    dispatch(updateDescCourse(data));
  };
  const handleImageClick = () => {
    refImage?.current.click();
  };
  const handleImageChange = async (event: any) => {
    setLoading(true)
    const file = event.target.files[0];
    console.log(file);
    const base64 = await convertBase64(file);
    setImage(file);
    const formData = new FormData();
    formData.append('file', file);
    const res: any = await dispatch(uploadFile(formData));
    if (res.meta.requestStatus === 'fulfilled' && res.payload) {
      console.log(res.payload?.data?.url);
      setLoading(false)

      dispatch(updateImageBanner(res.payload?.data?.url));
    }
  };
  const handleChangeTitle = (e:any)=>{
    setTitleCourse(e.target.value);

    dispatch(updateTitleCourse(e.target.value));
  };
  const handleExpireDate = (date:any)=>{
    setDate(date);
    dispatch(updateExpiredDate(moment(date).format('YYYY-MM-DD')));
  };
  const handleMoney = (e:any)=>{
    setMoney(Number(e.target.value));
    dispatch(updatePriceCourse(Number(e.target.value)));
  };
  const addSection = ()=>{
    const item = {
      // id: uuid4(),
      sectionName: '',
      lectures: [
        {
          // id: uuid4(),
          lectureName: '',
          lectureType: '',
          duration: 0,
          url: '',
          slug:genSlug(10)
        },
      ],
    };
    dispatch(addSectionCourse(item));
  };
  useEffect(()=>{
    const totalDurationSections:any = totDurationAllUpdate(updateCourse?.sections);
    dispatch(updateTotalDuration(totalDurationSections));
  },[updateCourse]);
  const updateCourseId = async(id:any)=>{
    const payload = {
      id:id,
      params:updateCourse
    };
    setLoading(true);
    const res = await dispatch(updateCourseTeacherAction(payload));
    if(res.meta.requestStatus === 'fulfilled'){
      console.log('🚀 ~ updateCourseId ~ res:', res);
      navigation(`/teacher/courses/${id}`);
      setLoading(false);
    }
  };

  return (
    <div className='p-[24px] w-full mx-auto'>
      <h1 className='text-[25px] font-semibold'>Cập nhập khoá học {updateCourse?.courseName}</h1>
      <div className='flex flex-col gap-5'>
        <div className='flex items-center gap-3'>
          <FormLabel >Tên khoá học</FormLabel>
          <Input className='flex-1' value={titleCourse} onChange={handleChangeTitle}/>
        </div>
        <div>
          <FormLabel>Mô tả khoá học</FormLabel>
          <Editor value={description} handleValue={handleDesc}/>
        </div>
        <div className='flex items-center gap-3'>
          <FormLabel>Thời gian hết hạn khoá học</FormLabel>
          <DatePicker
            onChange={handleExpireDate}
            selected={new Date(date)}
            className="w-full px-3 py-3 outline-none border-[1px] border-[#E9EAF0] placeholder:text-[#8C94A3] placeholder:font-normal placeholder:text-[14px]"
            placeholderText="Ngày sinh"
          />
        </div>
        <div className='flex items-center gap-3'>
          <FormLabel>Giá khoá học</FormLabel>
          <InputGroup className='flex-1'>
            <Input value={money} onChange={handleMoney}/>
            <InputRightAddon>VND</InputRightAddon>
          </InputGroup>
        </div>
        <div className="flex flex-col gap-y-3">
          <span className="font-medium text-[18px] text-[#1D2026]">
            Ảnh banner
          </span>
          <div className="grid grid-cols-[200px_1fr] gap-x-5">
            {image ? (
              <img
                src={!String(image).includes('https') ? URL.createObjectURL(image) : image}
                alt=""
                className="w-full h-[142px] object-cover"
              />
            ) : (
              <img
                src={imgFade}
                alt=""
                className="w-full h-[142px] object-cover"
              />
            )}
            {/* <img src={image ? URL.createObjectURL(image) : imgFade} alt="" /> */}
            <div className="text-[14px]">
              <p className="mb-[24px] text-[#6E7485]">
                Tải lên hình thu nhỏ khóa học của bạn ở đây.{' '}
                <span className="text-[#1D2026] font-medium">
                  Nguyên tắc quan trọng
                </span>
                : 1200x800 pixel hoặc tỷ lệ 12:8. Định dạng được hỗ trợ:{' '}
                <span className="text-[#1D2026] font-medium">
                  .jpg, .jpeg hoặc .png
                </span>
              </p>
              <div className="flex gap-x-8">
                <div
                  onClick={handleImageClick}
                  className="bg-[#FFEEE8] cursor-pointer w-fit flex items-center gap-x-[12px] h-[48px] px-[24px]"
                >
                  <button
                    type="button"
                    className="font-semibold text-[16px] text-[#FF6636]"
                  >
                                        {loadingImg ? (
                      <Spinner color='orange.500' />
                    ):"Tải ảnh lên"}
                  </button>
                  <BsCloudUpload className="text-[20px] text-[#FF6636]" />
                  <input
                    type="file"
                    ref={refImage}
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-5'>
          {
            updateCourse?.sections?.map((sectionItem:any,sectionIndex:number)=>(
              <CourseSection sectionItem={sectionItem} key={sectionItem?._id} sectionIndex={sectionIndex} courseDetail={updateCourse}/>
            ))
          }
        </div>
        <div className='flex justify-end'>

          <Button backgroundColor={'#FF6636'} onClick={addSection}>
            <FaPlus/>
          </Button>
        </div>
      </div>
      <Button isLoading={loading} onClick={()=>updateCourseId(updateCourse?._id)} backgroundColor={'#FF6636'} color={'white'}>Cập nhập khoá học</Button>
    </div>
  );
};

export default UpdateCourseMain;