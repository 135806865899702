import React, { useEffect } from 'react';
import google from '../../image/Login/google-logo-search-new-svgrepo-com.svg';
import { signInWithGoogle } from '../../config/firebase';
import { useAppDispatch } from '../../hooks/appHooks';
import { loginGoogleAction } from '../../store/actions/auth.action';
import { updateIsLogged, updateUserId } from '../../store/reducers/authSlice';
import { LocalStorage } from '../../utils/LocalStorage';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
const Google = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const loginGoogle = async()=>{
    try{

      const response:any = await dispatch(loginGoogleAction({}));
      if(response?.meta?.requestStatus === 'fulfilled'){
        if(response.payload.code === 200){
          if (
            response?.payload?.data?.infoUser?.roles[0]?.roleName === 'STUDENT'
          ) {
            dispatch(updateUserId(response?.payload.data?.infoUser));
            LocalStorage.setUserId(response?.payload.data.infoUser._id);
            dispatch(updateIsLogged(true));
            LocalStorage.setToken(response?.payload.data?.token);
            LocalStorage.setRefreshToken(response?.payload.data?.refreshToken);
            console.log(LocalStorage.getAccessToken());
  
            toast({
              title: 'Đăng nhập thành công',
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top-right',
            });
            setTimeout(() => {
              navigate('/');
            }, 1500);
          } else {
            toast({
              title: 'Lỗi đăng nhập',
              description: 'Tài khoản này không phải tài khoản của học sinh',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top-right',
            });
          }
        }else{
          LocalStorage.setToken(response?.payload?.data?.token);
          LocalStorage.setRefreshToken(response?.payload.data.refreshToken);
          console.log(response.payload.data.infoUser._id);
    
          dispatch(updateUserId(response?.payload.data.infoUser));
          dispatch(updateIsLogged(true));
          // console.log(response);
          toast({
            title: 'Đăng ký thành công',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top-right',
          });
          setTimeout(() => {
            navigate('/signup/otp');
          });
        }
        console.log('🚀 ~ loginGoogle ~ res:', response);
      }
    }catch(err:any){
    console.log("🚀 ~ loginGoogle ~ err:", err)

    }
  };
  const googleOpen = () => {
    window.open(`${process.env.REACT_APP_API_ENDPOINT}/auth/google`);
  };
  useEffect(()=>{
    // const intervalTime = setInterval(()=>{

    // },2000);
    loginGoogle();
    // return () => clearInterval(intervalTime);
  },[]);
  return (
    <div className="cursor-pointer flex items-center py-1 px-3 border-[1px] border-[#E9EAF0] w-full gap-x-2" onClick={googleOpen}>
      <img src={google} alt="google-icon" className="w-10" />
      <div className="font-semibold">Tiếp tục với Google</div>
    </div>
  );
};

export default Google;
