import { Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/appHooks';
import { transactionListAction } from '../../../store/actions/transaction.action';
import moment from 'moment';
import PagiantionNew from '../../../components/Pagination/PagiantionNew';
const TransactionMain = () => {
  const dispatch = useAppDispatch();
  const [loading,setLoading] = useState(false);
  const [listData,setListData] = useState<any>();
  const [page,setPage] = useState(1);
  const getListTransaction = async(page:any)=>{
    const payload = new URLSearchParams({
      page: String(page)
    });
    const res:any = await dispatch(transactionListAction(payload));
    if(res.meta.requestStatus === 'fulfilled'){
      setListData(res?.payload?.data);
    }
  };
  const handleChange = (page: number) => {
    setPage(page);
  };
  useEffect(()=>{
    getListTransaction(page);
  },[page]);
  return (
    <div className='p-[24px]'>
      <div>
        <h1 className='text-[25px] font-semibold text-[#FF6636]'>Lịch sử giao dịch</h1>
      </div>
      <div>
        <TableContainer>
          <Table variant='striped' colorScheme='orange'>
            
            <Thead>
              <Tr>
                <Th>ID giao dịch</Th>
                <Th>Tên người giao dịch</Th>
                <Th>Thời gian giao dịch</Th>
                <Th>Tên khoá học</Th>
                {/* <Th>Giáo viên</Th> */}
                <Th>Số tiền</Th>
                <Th>Phương thức thanh toán</Th>
              </Tr>
            </Thead>
            <Tbody>
              {listData?.listData?.map((item:any,index:number)=>(

                <Tr key={item?._id}>
                  <Td>{item?._id}</Td>
                  <Td>{item?.user?.fullname}</Td>
                  <Td>{moment(item?.createdAt).format('DD-MM-YYYY HH:mm:ss')}</Td>
                  <Td>{item?.orderDetails?.map((itemOrder:any,indexOrder:number)=>(
                    <div key={indexOrder}>{itemOrder?.course?.courseName}</div>
                  ))}</Td>
                  <Td>{item?.total}</Td>
                  <Td>{item?.paymentMethod}</Td>
                </Tr>
              ))}

            </Tbody>
          </Table>
          <div className="mt-4">
            <PagiantionNew
              onPageChange={handleChange}
              totalCount={listData?.total}
              pageSize={10}
              siblingCount={1}
              currentPage={page}
            />
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default TransactionMain;