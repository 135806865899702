import React from 'react';
import banner from '../../image/Navbar/153158b7-ef2b-4da5-9a19-4c75d50f73d2.jpg';
import Partner from './Partner';
import Courses from './Courses';
import Achivement from './Achivement';
import View from './View';
import Categories from './Categories';
import SliderBanner from './SliderBanner';
import {motion} from "framer-motion"
const Homepage = () => {
  const cardVariants = {
    hidden: {
      opacity: 0,
      y: -100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
      },
    },
  };


  return (
    <motion.div initial="hidden"
    animate="visible"
    variants={cardVariants}
className='text-black pt-[72px]'>
      <SliderBanner/>
      
      <Partner/>
      <Courses/>
      {/* <Achivement/> */}
      <View/>
      <Categories/>
    </motion.div>
  );
};

export default Homepage;