import React, { useEffect, useState } from 'react';
import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi';
import PagiantionNew from '../../components/Pagination/PagiantionNew';
import { useSelector } from 'react-redux';
import { selectUserCourse } from '../../store/reducers/courseSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/appHooks';
import { courseProgressAction } from '../../store/actions/course.action';

const Courses = () => {

  const userCourse = useSelector(selectUserCourse);
  return (
    <>
      <h1 className="font-semibold text-[24px] text-[#1D2026]">
        Courses ({userCourse?.length})
      </h1>
      <div className="flex flex-col gap-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-[24px] gap-y-[12px]">
          {userCourse?.map((course: any, index: number) => (
            <CourseItem course={course} key={course?._id}/>
          ))}
        </div>
        {/* <PagiantionNew  
          onPageChange={handleChange}
          totalCount={userCourse?.total}
          pageSize={10}
          siblingCount={1}
          currentPage={page} /> */}
      </div>
    </>
  );
};
const CourseItem = ({course}:any)=>{
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [progress,setProgress] = useState<any>();
  const getProgress = async(id:any)=>{
    try{
      const res:any = await dispatch(courseProgressAction(id));
      if(res.meta.requestStatus === 'fulfilled'){
        setProgress(res?.payload?.data);
      }
    }catch(err){
      console.log('🚀 ~ getProgress ~ err:', err);
      
    }
  };
  useEffect(()=>{
    getProgress(course?._id);
  },[course]);
  return (
    <div key={course?._id}>
      <div>
        <img
          src={course?.thumbnail_url}
          alt=""
          className="h-[220px] w-full object-cover"
        />
        <div className="p-[16px] h-[100px] flex flex-col gap-y-[6px] bg-white border-[1px] border-[#E9EAF0]">
          <span className="text-[#6E7485] text-[12px] line-clamp-2">
            {course?.courseName.split('-')[0]}
          </span>
          <h1 className="text-[#1D2026] text-[14px] font-medium">
            {course?.courseName}
          </h1>
        </div>
        <div className="flex flex-col gap-2 p-[16px] border-[1px] border-[#E9EAF0]">
          <div className='grid grid-cols-2 items-center'>
            <button
              onClick={() => navigate(`/courses/${course?._id}`)}
              className="w-full py-[8px] px-[4px] text-[14px] font-semibold bg-[#FFEEE8] text-[#FF6636]"
            >
            Xem bài giảng
            </button>
            <div className="text-right text-[12px] text-[#23BD33]">
              <span>{progress?.progress}</span>% complete
            </div>
          </div>
          {/* <Link to={`/rate/${course?._id}`} className="w-full cursor-pointer py-[10px] px-[4px] text-[14px] font-semibold bg-[#FFEEE8] text-[#FF6636] text-center">Đánh giá khoá học</Link> */}
        </div>
      </div>
    </div>
  );
};
export default Courses;
