import React, { useEffect, useState } from 'react';
import { Switch, useDisclosure } from '@chakra-ui/react';
import { BsPencilSquare } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa6';
import ModalUpdateBanner from './ModalUpdateBanner';
import ModalDeleteBanner from './ModalDeleteBanner';
import parse from 'html-react-parser';
import { useAppDispatch } from '../../../hooks/appHooks';
import { deleteBannerAction, updateBannerAction } from '../../../store/actions/banner.action';
const CardBanner = ({item,getBanner}:any) => {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen:isOpenDelete, onOpen:onOpenDelete, onClose:onCloseDelete } = useDisclosure();
  const [isChecked, setIsChecked] = useState(item?.activate);
  const handleChecked = async(e:any)=>{
    const params = {
      id:item?._id,
      params: {
        activate:e.target.checked
      }
    };
    setIsChecked(e.target.checked);
    console.log('🚀 ~ handleChecked ~ params.params.e.target.checked:', e.target.checked);
    const res = await dispatch(updateBannerAction(params));
    if(res.meta.requestStatus === 'fulfilled'){
      console.log('🚀 ~ handleChecked ~ res:', res); 
    }
  };
  useEffect(()=>{
    setIsChecked(item?.activate);
  },[item]);
  
  return (
    <div className='gap-3 flex items-center'>
      <div className="flex-1 w-full">
        <img className=' w-full h-[200px] object-cover' src={item?.url ? item?.url : 'https://images.pexels.com/photos/22033622/pexels-photo-22033622/free-photo-of-hoan-ho.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="" />
      </div>
      <div className='w-[500px] text-center'>
        <h1 className='text-[16px]'>
          {item?.title && parse(item?.title)}
        </h1>
      </div>
      {/* <div className='w-[300px] text-center'>
        <h1 className='text-[16px]'>27/04/2002</h1>
      </div> */}
      <div className='w-[100px] flex justify-center'>
        <Switch colorScheme='orange' isChecked={isChecked} onChange={handleChecked}/>
      </div>
      <div className='w-[200px]'>
        <div className='gap-4 flex items-center justify-center'>
          {/* <BsPencilSquare onClick={onOpen} className='cursor-pointer text-[20px] text-[#FF6636]'/> */}
          <FaTrash onClick={onOpenDelete} className='cursor-pointer text-[20px] text-[#FF6636]'/>
        </div>
      </div>
      {/* <ModalUpdateBanner item={item} isOpen={isOpen} onClose={onClose}/> */}
      <ModalDeleteBanner item={item} getBanner={getBanner} isOpen={isOpenDelete} onClose={onCloseDelete}/>
    </div>
  );
};

export default CardBanner;