import React, { useEffect } from 'react';
import TabProfile from './TabProfile';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../store/reducers/authSlice';
import { useAppDispatch } from '../../hooks/appHooks';
import { getUserInfo } from '../../store/actions/user.action';
import unknown_user from '../../image/Homepage/unknown_user.jpeg';
import {motion} from "framer-motion"
const Profile = () => {
  const cardVariants = {
    hidden: {
      opacity: 0,
      y: -100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
      },
    },
  };
  const userInfo: any = useSelector(selectUserInfo);
  const dispatch = useAppDispatch();
  const getUserInfoDetail = async () => {
    const res = await dispatch(getUserInfo({}));
    if (res.meta.requestStatus === 'fulfilled' && res.payload) {
      console.log(res);
    }
  };
  useEffect(() => {
    getUserInfoDetail();
  }, []);
  return (
    <motion.div initial="hidden"
    animate="visible"
    variants={cardVariants} className="pt-[100px] pb-[60px] px-[24px] max-w-[1200px] w-full mx-auto">
      <div className="flex gap-y-3 flex-col lg:flex-row justify-between items-center border-[1px] w-full border-[#FF6636] p-[40px] bg-[#FF6636]">
        <div className="flex gap-x-[24px] items-center">
          <img
            src={userInfo?.avatar ? userInfo?.avatar : unknown_user}
            alt=""
            className="w-[110px] h-[110px] rounded-full"
          />
          <div className="flex flex-col gap-y-[5px]">
            <h1 className="text-[24px] text-white font-semibold">
              {userInfo?.username} ({userInfo?.fullname})
            </h1>
            <span className="text-white">Học sinh</span>
          </div>
        </div>
        <div>
          <div className="text-[18px] font-semibold px-[32px] py-[10px] w-[200px] text-center bg-[#FFEEE8] text-[#FF6636]">
            {userInfo &&
              userInfo.roles &&
              userInfo?.roles[0].roleName === 'STUDENT' &&
              'Học sinh'}
          </div>
        </div>
      </div>
      <div>
        <TabProfile />
      </div>
    </motion.div>
  );
};

export default Profile;
