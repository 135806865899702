import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import ListVideoChapter from './ListVideoChapter';
import { timeLecture } from '../../utils/lib';
import { useAppDispatch } from '../../hooks/appHooks';
import { courseProgressAction } from '../../store/actions/course.action';
import { useLocation } from 'react-router-dom';
const VideoCourseList = ({ courseDetail }: any) => {
  const [progress,setProgress] = useState<any>();
  const dispatch = useAppDispatch();
  const search = useLocation().search;

  const paramsLecture = new URLSearchParams(search).get('slug');
  const idLecture = new URLSearchParams(search).get('idLecture');
  const getProgressCourse = async(id:any)=>{
    try {
      const res:any = await dispatch(courseProgressAction(id));
      if(res?.meta?.requestStatus === 'fulfilled'){
        setProgress(res?.payload?.data);
      }
    } catch (error) {
      console.log('🚀 ~ getProgressCourse ~ error:', error);
      
    }
  };
  const checkLectureHaveLearn = (index:any, length:any)=>{
    if(progress?.currentSection > index){
      return length;
    }
    if(progress?.currentSection === index){
      return progress?.currentLecture;
    }
    if(progress?.currentSection < index){
      return 0;
    }
  };
  useEffect(()=>{
    if(courseDetail){
      getProgressCourse(courseDetail?._id);
    }
  },[courseDetail,idLecture,paramsLecture]);
  return (
    <Accordion className="h-full" allowMultiple>
      {courseDetail?.sections?.map((section: any, index: any) => (
        <AccordionItem key={section?._id} isDisabled={index+1 <= progress?.currentSection? false : true}>
          <div>
            <AccordionButton flexDirection="column" alignItems="start" w="100%">
              <Box
                flex="1"
                className="flex items-center justify-between w-full"
              >
                <Box
                  as="span"
                  textAlign="left"
                  fontFamily="16px"
                  fontWeight={600}
                >
                  {section?.sectionName}
                </Box>
                <AccordionIcon />
              </Box>
              <div className="text-[13px]">
                <span>
                  {checkLectureHaveLearn(index+1,section?.lectures.length) }/{section?.lectures.length} |{' '}
                  {timeLecture(section?.lectures)} phút
                </span>
              </div>
            </AccordionButton>
          </div>
          <AccordionPanel p={0}>
            {section?.lectures?.map((lecture: any, indexLec: any) => (
              <ListVideoChapter
                id={lecture?._id}
                code={lecture?.url}
                lecture={lecture}
                key={lecture?._id}
                index={indexLec}
                slug={lecture?.slug}
                progress={progress}
                indexSection = {index}
                idLecture={idLecture}
                sectionName={section?.sectionName}
              />
            ))}
          </AccordionPanel>
        </AccordionItem>
      ))}

      {/* <AccordionItem>
        <div>
          <AccordionButton flexDirection="column" alignItems="start" w="100%">
            <Box flex="1" className="flex items-center justify-between w-full">
              <Box
                as="span"
                textAlign="left"
                fontFamily="16px"
                fontWeight={600}
              >
                Chương 1: Giới thiệu
              </Box>
              <AccordionIcon />
            </Box>
            <div className="text-[13px]">
              <span>0/5 | 26 phút</span>
            </div>
          </AccordionButton>
        </div>
        <AccordionPanel p={0}>
          <ListVideoChapter code="jasdkasdasj" />
        </AccordionPanel>
      </AccordionItem> */}
    </Accordion>
  );
};

export default VideoCourseList;
