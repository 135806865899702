import { createAsyncThunk } from '@reduxjs/toolkit';
import { transactionService } from '../services/transaction.service';
export const transactionListAction = createAsyncThunk(
  'transaction/transactionList',
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const res = await transactionService.getOrderTransaction(params);
      return res;
    } catch (err) {
      rejectWithValue(err);
      return err;
    }
  }
);