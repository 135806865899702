import React, { useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import { buttonList, createBlogProps } from '../../utils/type';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createBlog } from '../../schema/schema';
import TabInput from '../../components/TagInput/TabInput';
import { Button, useToast } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { useAppDispatch } from '../../hooks/appHooks';
import { getDetailAction, updateBloglAction } from '../../store/actions/blog.action';
import { uploadFile } from '../../store/actions/course.action';
const UpdateBlog = ({isOpen, onClose,item,getListBlog}:any) => {
  const toast = useToast();
  const [tags, setTags] = useState(item?.tags);
  const [detailBlog, setDetailBlog] = useState<any>();
  const [title,setTitle] = useState('');
  const [content,setContent] = useState('');
  const [previewContent, setPreviewContent] = useState('');
  const dispatch = useAppDispatch();
  const getDetailBlog = async(id:any)=>{
    const res:any = await dispatch(getDetailAction(String(id)));
    if(res.meta.requestStatus === 'fulfilled'){
      console.log('🚀 ~ getDetailBlog ~ res:', res);
      setDetailBlog(res?.payload?.data);
      setTitle(res?.payload?.data?.title);
      setContent(res?.payload?.data?.content);
      setPreviewContent(res?.payload?.data?.previewContent);
      // getListlBlogTags(res?.payload?.data?.tags);
    }
  };
  /**
   * @type {React.MutableRefObject<SunEditor>} get type definitions for editor
   */
  const editor: any = useRef();
  const [valuesDesc, setValuesDesc] = useState('');
  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };
  const {handleSubmit,register,getValues,setValue,formState:{errors, isSubmitting}} = useForm<createBlogProps>({
    defaultValues:{
      title:item?.title && parse(item?.title),
      desc:item?.content && parse(item?.content),
      tag:[],
      previewContent:item?.previewContent && parse(item?.previewContent)
    },
    
    resolver:yupResolver(createBlog)
  });
  const handleChangeTitle = (value:any)=>{
    setTitle(value);
  };
  const handleChangeDesc = (value:any)=>{
    setContent(value);
  };
  const handleChangePreviewContent = (value:any) => {
    console.log('🚀 ~ handleChangeDesc ~ value:', value);
    
    setPreviewContent(value);
  };
  const handleSubmitUpdate = async(e:any)=>{
    e.preventDefault();
    const payload = {
      id:item?._id,
      params:{
        title:title,
        tags:tags,
        content:content,
        previewContent:previewContent
      }
    };
    try{
      const res = await dispatch(updateBloglAction(payload));
      if(res.meta.requestStatus === 'fulfilled'){
        console.log('🚀 ~ handleSubmitUpdate ~ res:', res);
        getListBlog();
        onClose();
        toast({
          title: 'Cập nhập thành công',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position:'top-right'
        });
      }
    }catch(e:any){
      console.log('🚀 ~ handleSubmitUpdate ~ e:', e);
      toast({
        title: 'Cập nhập không thành công',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position:'top-right'
      });
    }
  };
  useEffect(()=>{
    getDetailBlog(item?._id);
    
  },[item]);
  const handleImageUploadBefore = (
    files: File[],
    info: object,
    uploadHandler: (data: { result: Array<{ url: string; name: string; size: number }> }) => void
  ): any => {
    (async () => {
      try {
        const formData = new FormData();
        formData.append('file', files[0]);
  
        console.log('INFO==>', info);
  
        const response:any = await dispatch(uploadFile(formData));
  
        const data = response?.payload?.data;
  
        if (data && data.url) {
          uploadHandler({
            result: [
              {
                url: data.url,
                name: files[0].name,
                size: files[0].size,
              },
            ],
          });
        } else {
          uploadHandler({ result: [] });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        uploadHandler({ result: [] });
      }
    })();
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cập nhập bài viết</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form className='flex flex-col gap-4' onSubmit={handleSubmitUpdate}>
            <div>
              <div className="text-[20px] text-[#FF6636] font-semibold mb-[10px]">Tiêu đề</div>
              <div>
                <SunEditor
                  setOptions={{
                    katex:katex,
                    buttonList: buttonList,
                  }}
                  defaultValue={detailBlog?.title && detailBlog?.title}
                  onChange={handleChangeTitle}
                  getSunEditorInstance={getSunEditorInstance}
                  height=""
                  width="100%"
                />
              </div>
            </div>
            <div>
              <div className="text-[20px] text-[#FF6636] font-semibold mb-[10px]">Tag</div>
              <TabInput tags={tags} setTags={setTags}/>
            </div>
            <div className="text-[20px] text-[#FF6636] font-semibold mb-[10px]">Mô tả ngắn</div>
            <div>
              <SunEditor
                setOptions={{
                  katex:katex,
                  buttonList: buttonList,
                }}
                defaultValue={detailBlog?.previewContent && detailBlog?.previewContent}
                onChange={handleChangePreviewContent}
                getSunEditorInstance={getSunEditorInstance}
                height="150px"
                width="100%"
              />
            </div>
            <div>
              <div className="text-[20px] text-[#FF6636] font-semibold mb-[10px]">Viết bài</div>
              <div>
                <SunEditor
                  setOptions={{
                    katex:katex,
                    buttonList: buttonList,
                  }}
                  defaultValue={detailBlog?.content && detailBlog?.content}
                  onChange={handleChangeDesc}
                  getSunEditorInstance={getSunEditorInstance}
                  onImageUploadBefore={handleImageUploadBefore}
                  height="300px"
                  width="100%"
                />
              </div>
            </div>
            <Button
              bg="#FF6636"
              color="#ffffff"
              fontSize="14px"
              _hover={{
                bg: '#f85b2b',
              }}
              type='submit'
            >Tạo bài viết</Button>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button bg="#FF6636" mr={3} onClick={onClose} color="white">
              Đóng
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    
  );
};

export default UpdateBlog;