import { io } from 'socket.io-client';
import React from 'react';
import { LocalStorage } from '../utils/LocalStorage';

export const socket = io(String(process.env.REACT_APP_HOST_SOCKET), {
  // transports: ['websocket','pooling', 'flashsocket' ],
  reconnectionDelay: 1000, // defaults to 1000
  reconnectionDelayMax: 2000, // defaults to 5000,
  query: { authorization:`Bearer ${LocalStorage.getAccessToken()?.toString()}` },
  extraHeaders:{
    authorization: `Bearer ${LocalStorage.getAccessToken()?.toString()}`,
  },
  withCredentials: true,
  // key:`Bearer ${LocalStorage.getAccessToken()?.toString()}`,
  // auth:{
  //   Authorization:LocalStorage.getAccessToken()
  // }
});
export const connectSocket = () => {
  const token = LocalStorage.getAccessToken()?.toString();
  if (token) {
    socket.io.opts.query = { authorization: `Bearer ${token}` };
    socket.io.opts.extraHeaders = { authorization: `Bearer ${token}` };
    socket.connect();
  } else {
    console.warn('No token found. Socket will not connect.');
  }
};

export const disconnectSocket = () => {
  if (socket.connected) {
    socket.disconnect();
  }
};
export const SocketContext = React.createContext<any>(null);