import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react';
import TabUpdate from '../TabUpdate';
import { useSelector } from 'react-redux';
import {
  selectExam,
  selectExamTemp,
} from '../../../../store/reducers/examSlice';
import { useAppDispatch } from '../../../../hooks/appHooks';
import { updateExam } from '../../../../store/actions/exam.action';
interface UpdateProp {
  isOpenUpdate: boolean;
  onCloseUpdate: () => void;
  item: any;
  getExams: any;
}
const ModalUpdateExam = ({
  getExams,
  isOpenUpdate,
  item,
  onCloseUpdate,
}: UpdateProp) => {
  const [loading,setLoading] = useState(false)
  const toast = useToast()
  const exam = useSelector(selectExamTemp);
  console.log('🚀 ~ exam:', exam);
  const dispatch = useAppDispatch();
  const update = async () => {
    setLoading(true)
    const payload = {
      id: item?._id,
      params: exam,
    };
    const respons = await dispatch(updateExam(payload));
    if (respons.payload && respons.meta.requestStatus === 'fulfilled') {
      onCloseUpdate();
      setLoading(false)
      getExams(1);
      toast({
        title:"Cập nhập bài tập thành công",
        status:"success",
        duration:4000,
        isClosable:true,
        position:"top-right"
      })
    }else{
      setLoading(false);
      toast({
        title:"Cập nhập thất bại",
        status:"error",
        duration:4000,
        isClosable:true,
        position:"top-right"
      })
    }
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpenUpdate}
      onClose={onCloseUpdate}
      isCentered
      id={item?.title}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cập nhập bài tập</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TabUpdate item={item} />
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#FF6636"
            color="white"
            _hover={{ bg: '#fe5c2b' }}
            mr={3}
            onClick={onCloseUpdate}
          >
            Đóng
          </Button>
          <Button variant="ghost" onClick={update}>
            Cập nhập bài tập
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalUpdateExam;
