/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Radio,
  RadioGroup,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { useAppDispatch } from '../../hooks/appHooks';
import { getSubjects } from '../../store/actions/user.action';
import useQueryParams from '../../hooks/useSearchParams';
import useSetQueryParams from '../../hooks/useSetQuery';
import { LocalStorage } from '../../utils/LocalStorage';
import { useLocation, useSearchParams } from 'react-router-dom';
const Filter = ({ setPage }: any) => {
  const [searchParams]:any = useSearchParams();
  const [checkPath,setCheckPath] = useState<any>();
  const [startTime,setStartTime] = useState<any>();
  const [endTime,setEndTime] = useState<any>();
  const [startPrice,setStartPrice] = useState<any>();
  const [endPrice,setEndPrice] = useState<any>();

  const dispatch = useAppDispatch();
  const userId = LocalStorage.getUserId();
  const queryParam = useQueryParams(
    {
      search: '',
      categoryId: '',
      subCategoryId: '',
      startPrice: '',
      endPrice: '',
      page: 1,
      userId: '',
      startDuration: '',
      endDuration: '',
    },
    window.location.href
  );
  const setQuery = useSetQueryParams();
  const [subjects, setSubjects] = useState<any>([]);
  const getListSubject = async () => {
    const res: any = await dispatch(getSubjects({}));
    if (res.payload && res.meta.requestStatus === 'fulfilled') {
      setSubjects(res?.payload.data);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPath(e.target.value);
    setQuery(queryParam.queryParams, {
      search: e.target.value,
    });
    setPage(1);
  };
  useEffect(() => {
    getListSubject();
    
  }, []);
  useEffect(()=>{
    if(searchParams){
      setEndPrice(searchParams?.get('endPrice')?.toString());
      setStartPrice(searchParams?.get('startPrice')?.toString());
      setEndTime(searchParams?.get('endDuration')?.toString());
      setStartTime(searchParams?.get('startDuration')?.toString());
      setCheckPath(searchParams?.get('search')?.toString());
    }
  },[searchParams]);
  const startPriceOnChange = (e: any) => {
    setStartPrice(e.target.value);
    setTimeout(() => {
      setQuery(queryParam.queryParams, {
        startPrice: +e.target.value,
      });
    }, 1000);
  };
  const endPriceOnChange = (e: any) => {
    setEndPrice(e.target.value);

    setTimeout(() => {
      setQuery(queryParam.queryParams, {
        endPrice: +e.target.value,
      });
    }, 1000);
  };
  const startTimeOnChange = (e: any) => {
    setStartTime(e.target.value);
    setTimeout(() => {
      setQuery(queryParam.queryParams, {
        startDuration: +e.target.value,
      });
    }, 1000);
  };
  const endTimeOnChange = (e: any) => {
    setEndTime(e.target.value);
    setTimeout(() => {
      setQuery(queryParam.queryParams, {
        endDuration: +e.target.value,
      });
    }, 1000);
  };
  const resetFilter = () => {
    setQuery(queryParam.queryParams, {
      search: '',
      categoryId: '',
      subCategoryId: '',
      startPrice: '',
      endPrice: '',
      page: 1,
      userId: userId ? userId : '',
      startDuration: '',
      endDuration: '',
    });
    setEndPrice('');
    setEndTime('');
    setStartPrice('');
    setStartTime('');
  };
  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight={600}>
              Thời lượng video
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} className="flex flex-col w-full">
          <div className="flex flex-col gap-y-3 items-center ">
            <InputGroup size="md">
              <Input
                onChange={startTimeOnChange}
                placeholder="Min"
                color="#FF6636"
                focusBorderColor="#FF6636"
                value={startTime}
              />
              <InputRightAddon children="giờ học" />
            </InputGroup>
            <span>đến</span>
            <InputGroup size="md">
              <Input
                onChange={endTimeOnChange}
                placeholder="Max"
                color="#FF6636"
                focusBorderColor="#FF6636"
                value={endTime}
              />
              <InputRightAddon children="giờ học" />
            </InputGroup>
          </div>
        </AccordionPanel>
      </AccordionItem>
      {/* <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight={600}>
              Tính năng
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} className="flex flex-col">
          <Checkbox colorScheme="purple" value="">
            Bài tập <span>(614)</span>
          </Checkbox>
        </AccordionPanel>
      </AccordionItem> */}
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight={600}>
              Chủ đề
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <RadioGroup className="flex flex-col" name='môn học'>
            {subjects.map((subject: any, index: any) => (
              <Radio
                colorScheme="orange"
                value={subject?.subjectName}
                key={subject._id}
                defaultChecked={subject?.subjectName === checkPath ? true : false}
                onChange={handleChange}
              >
                {subject?.subjectName}
              </Radio>
            ))}
          </RadioGroup>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight={600}>
              Giá
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} className="flex flex-col">
          <div className="flex flex-col gap-y-3 items-center ">
            <InputGroup size="md">
              <Input
                onChange={startPriceOnChange}
                placeholder="Min"
                color="#FF6636"
                focusBorderColor="#FF6636"
                value={startPrice}
              />
              <InputRightAddon children="VND" />
            </InputGroup>
            <span>đến</span>
            <InputGroup size="md">
              <Input
                onChange={endPriceOnChange}
                placeholder="Max"
                color="#FF6636"
                focusBorderColor="#FF6636"
                value={endPrice}
              />
              <InputRightAddon children="VND" />
            </InputGroup>
          </div>
        </AccordionPanel>
      </AccordionItem>

      <button
        onClick={resetFilter}
        className="mt-3 px-[12px] py-[8px] bg-[#FF6636] text-white text-[14px] font-medium"
      >
        Đặt lại filter
      </button>
    </Accordion>
  );
};

export default Filter;
